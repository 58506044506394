import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import './index.css';
import App from './App.tsx';
import initializeStore from './lib/store';

const container = document.getElementById('root');
const parentId = container.getAttribute('data-parent');
const type = container.getAttribute('data-type');

ReactDOM.render(
  <Provider store={initializeStore()}>
    <App type={type} parentId={parentId} />
  </Provider>,
  container);