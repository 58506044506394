import React from 'react';
import { map } from 'lodash';
import { EFilterType } from '../../../lib/enums';
import './ArrayFilter.css';

interface IProps {
  block: any;
  filterType: EFilterType;
  setFilter: (val, state, filterType) => void;
}
const ArrayFilter: React.FC<IProps> = ({ block, filterType, setFilter }) => {
  return (
    <div>
      {map(block.filter.all, (option) => {
        const handleCheckboxClick = () => setFilter( option, block, filterType);
        return (
          <div key={option} className="filters--checkbox--item">
            <label>
              <input
                onChange={handleCheckboxClick}
                type="checkbox"
                checked={block.filter.selected.includes(option)}
              />
              {option}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default ArrayFilter;
