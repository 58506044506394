import React from 'react';
import { connect } from 'react-redux';
import { setFilter, toggleFilterOpen } from './actions/filterActions';
import { loadProducts } from './actions/productsActions';
import { setPrintersSorting } from './actions/sortingActions';
import { EFilterType, EProductType } from './lib/enums';
import { IAppState, IPriceFilterBlock, IPrintersFilters, IProduct, IRange } from './lib/models';
import { getFilteredPrinters, getFilteredScanners, getFilteredPens, getFilteredConsumables } from './selectors';
import CatalogLayout from './components/catalog/CatalogLayout';
import ProductFilters from './components/catalog/ProductFilters';
import ProductList from './components/catalog/ProductList';

interface IProps {
  filters: IPrintersFilters;
  dispatch: any;
  products: IProduct[];
  onSetFilter: (val: IRange, state: IPriceFilterBlock, filterType: EFilterType, productType) => void;
  onToggleFilterOpen: (filterType: EFilterType, productType) => void;
  setSortOrder: () => void;
  sortOrder: string;
  loadProducts: (typeId: string) => void;
  type: string;
  parentId: string;
}

class App extends React.Component<IProps> {
  componentDidMount() {
    this.props.loadProducts(this.props.parentId);
  }

  handleSetSortOrder = () => this.props.setSortOrder()

  handleSetFilter = (val: IRange, state: IPriceFilterBlock, filterType: EFilterType) => 
    this.props.onSetFilter(val, state, filterType, this.props.type)
  handleToggleOpen = (filterType: EFilterType) => this.props.onToggleFilterOpen(filterType, this.props.type)

  render() {
    const { filters, products, sortOrder, type } = this.props;
    return (
      <CatalogLayout>
        <ProductFilters
          filters={filters}
          setFilter={this.handleSetFilter}
          onToggleFilterOpen={this.handleToggleOpen}
          type={type}
        />
        <ProductList
          products={products}
          sortOrder={sortOrder}
          setSortOrder={this.handleSetSortOrder}
        />
      </CatalogLayout>
    )
  }
}

const mapStateToProps = (state: IAppState, ownProps: IProps) => {
  switch(ownProps.type) {
    case EProductType.PRINTER:
      return  {
        filters: state.filters.printers,
        products: getFilteredPrinters(state),
        sortOrder: state.sorting.printers,
      };
    case EProductType.SCANER:
      return  {
        filters: state.filters.scanners,
        products: getFilteredScanners(state),
        sortOrder: state.sorting.scanners,
      };
    case EProductType.PEN:
      return  {
        filters: state.filters.pens,
        products: getFilteredPens(state),
        sortOrder: state.sorting.pens,
      };
    case EProductType.CONSUMABLE:
      return  {
        filters: state.filters.consumables,
        products: getFilteredConsumables(state),
        sortOrder: state.sorting.consumables,
      };

  }
};

const mapDispatchToProps = (dispatch: any) => ({
  onSetFilter: (val: IRange, state: IPriceFilterBlock, filterType: EFilterType, productType) =>
    dispatch(setFilter(val, state, filterType, productType)),
  onToggleFilterOpen: (filterType: EFilterType, productType) => dispatch(toggleFilterOpen(filterType, productType)),
  setSortOrder: () => dispatch(setPrintersSorting()),
  loadProducts: (parentId) => dispatch(loadProducts(parentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);