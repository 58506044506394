import { keyBy, map } from 'lodash';
import { IProduct, IProductsState } from '../lib/models';
import { IProductRaw } from '../lib/modelsAPI';

export const processProducts = (products): IProductsState  => ({
  allIds: products.map((p) => p.id),
  byId:  keyBy(products.map((p: IProductRaw) => processProduct(p)), 'id'),
});

export const processProduct = (product: IProductRaw): IProduct => {
  return {
    ...product,
    price: parseInt(product.price, 10),
    brand: product.brand,
    category: product.product_category ? product.product_category.id : null,
    chamberHeight: product.chamber_height ? parseInt(product.chamber_height, 10) : null,
    chamberLength: product.chamber_height ? parseInt(product.chamber_length, 10) : null,
    chamberType: product.chamber_type,
    chamberWidth: product.chamber_height ? parseInt(product.chamber_width, 10) : null,
    heatedBed: product.heated_bed_enum ? product.heated_bed_enum : null,
    layerResolution: product.layer_resolution,
    pictures: map(product.pictures, (p) => p.directus_files_id.filename),
    technology: product.technology,
    type: product.product_type,
    uri: product.uri,
  };
};
