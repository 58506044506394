import { combineReducers, Reducer } from 'redux';
import { IAppState } from '../lib/models';
import filtersReducer from './filtersReducer';
import productsReducer from './productsReducer';
import sortingReducer from './sortingReducer';

export const reducers: Reducer<IAppState> = combineReducers<IAppState>({
  filters: filtersReducer,
  products: productsReducer,
  sorting: sortingReducer,
});
