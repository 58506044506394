import React from 'react';
import './CatalogTools.css';
import SortIcon from '../../icons/Sort';

interface IProps {
  onChangeSorting: () => void;
  sortOrder: string | null;
}

const CatalogTools: React.FC<IProps> = ({ onChangeSorting, sortOrder }) => (
  <div className="catalog-tools--container">
    <div className="catalog-tools--inner">
      <div
        className="catalog-tools--sorting"
        onClick={onChangeSorting}
      >
        <span>ЦЕНА</span>
        <span className="sort--icon">
          <SortIcon direction={sortOrder} width="13px" />
        </span>
      </div>
    </div>
  </div>
);

export default CatalogTools;
