import React from 'react';
import { EFilterType } from '../../../lib/enums';
import './BooleanFilter.css';

interface IProps {
  block: any;
  filterType: EFilterType;
  setFilter: (val, state, filterType) => void;
}

const BooleanFilter: React.FC<IProps> = ({ block, filterType, setFilter }) => {
  const handleCheckboxClick = () => setFilter(!block.filter, block, filterType);
  return (
    <label className="filters--checkbox--item">
      <input
        onChange={handleCheckboxClick}
        type="checkbox"
        checked={block.filter}
      />
      Есть
    </label>
  );
};

export default BooleanFilter;
