import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { API_HOST } from '../../../configuration/app.config';
import { IProduct } from '../../../lib/models';
import './ProductItem.css';

export interface IProps {
  product: IProduct;
  view?: string;
}

const pictureUrl = (product: IProduct) => {
  if (isEmpty(product.picture)) {
    return `${API_HOST}assets/images/image-placeholder.png`;
  }
  return `${API_HOST}${product.picture}`;
};

const ProductItem: React.FC<IProps> = ({ product, view = 'grid' }) => (
  <div className={`product-item--container ${view}`}>
    <a href={`${API_HOST}${product.uri}`}>
      <div className="product-item">
        <div className="product-item--picture" style={{ backgroundImage: `url(${pictureUrl(product)})` }} />
        <div className="product-item--description">
          <p className="product-item--description--name">{product.name}</p>
          <p className="product-item--description--brand">{product.brand}</p>
        </div>
        <div className="product-item--description--price">
          <span>{product.price.toLocaleString('ru-RU')}<span> ₽</span></span>
        </div>
      </div>
    </a>
  </div>
);

export default ProductItem;
