import React from 'react';

export default ({ color = '#000', size }): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="10 0 100 100"
      height={size}
      width={size}
    >
      <path
        fill={color}
        d="M 81.998 24.001
           H 63.236
           c -.912 -3.961 -4.461 -6.927 -8.695 -6.927
           s -7.783 2.966-8.695 6.927
           H 27.085
           a 2 2 0 0 0 0 4
           h 18.762
           c .912 3.963 4.46 6.929 8.694 6.929
           s 7.782 -2.966 8.694 -6.929
           h 18.763
           a 2 2 0 0 0 0 -4
           z
           M 54.541 30.93
           a 4.935 4.935 0 0 1 -4.929 -4.931 4.932 4.932 0 0 1 4.929 -4.925
           c 2.634 0 4.772 2.079 4.904 4.68 -.011.082 -.025.162
              -.025.247 0 .084.015.164.024.246-.131 2.602-2.27 4.683-4.903 4.683
           z
           M 81.998 62
           h -4.177
           c -.911-3.961-4.46-6.928-8.695-6.928
           S 61.342 58.039 60.431 62
           H 27.085
           a 2 2 0 0 0 0 4
           h 33.347
           c .913 3.962 4.461 6.928 8.694 6.928
           S 76.907 69.962 77.82 66
           h 4.178
           a 2 2 0 0 0 0-4
           z
           m -12.872 6.928
           a 4.935 4.935 0 0 1-4.929-4.931
           c 0-2.716 2.211-4.925 4.929-4.925
           s 4.929 2.209 4.929 4.925
           a 4.935 4.935 0 0 1-4.929 4.931
           z
           m 12.872-25.927
           H 48.652
           c -.911-3.961-4.46-6.928-8.695-6.928
           s -7.784 2.967-8.695 6.928
           h -4.177
           a 2 2 0 0 0 0 4
           h 4.178
           c .913 3.962 4.461 6.928 8.694 6.928
           s 7.781-2.966 8.694-6.928
           h 33.347
           a 2 2 0 0 0 0-4
           z
           m -42.041 6.928
           a 4.935 4.935 0 0 1-4.929-4.931
           c 0-2.716 2.211-4.925 4.929-4.925
           s 4.929 2.209 4.929 4.925
           a 4.935 4.935 0 0 1-4.929 4.931
           z"
      />
    </svg>
  );
};
